<template>
  <div>
    <b-card title="Witaj w Twoim panelu TomaszKrawczyk!">
      <b-card-text class="text-center">

      </b-card-text>
    </b-card>

  </div>
</template>

<script>
import { BCard, BCardText } from 'bootstrap-vue'
import { email, required } from '@core/utils/validations/validations'

export default {
  components: {
    BCard,
    BCardText,
  },
  data() {
    return {
      introImg: require('@/assets/images/chemmaster/chemmaster-pani-sonia-witaj.png'),
    }
  },
}
</script>

<style>

</style>
